.readerscard {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 5px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  }
  .readersimgback {
    background: url('../../../public/img/location/2.jpg') no-repeat;
    background-size: 100% 100%;
    opacity: .8;
  }
  .readersheader .readerslogo {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-top: 5px;
  }
  
  h1 {
    font-size: 1.8rem !important;
    font-weight: bold;
    margin: 5px 0;
    color: #fff;
  }
  
  .readerstagline h2{
    color: #000;
    font-weight: bold;
    font-size: 1.2rem !important;
    margin-bottom: 10px;
  }
  
  .readerssubtitle {
    color: #000;
    font-size: 1.5rem;
    margin-bottom: -5px;
    font-weight: bold;
  }
  
  .readerscontent {
    margin-top: 0px;
  }
  
  .readerscontact-item {
    margin: 2px 0;
    font-size: 1.2.rem;
  }
  
  .readerscontact-item a {
    color: #fff !important;
    text-decoration: none;
  }
  
  .readerscontact-item a:hover {
    text-decoration: underline;
  }
  
  .readersbranches {
    margin-top: -20px;
    background:linear-gradient(to right, #FFA500 0%, #F4BB44 100%);
  }
  
  .readersbranches h4 {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .readersbranches ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    color: #fff !important;
  }
  
  .readersbranches li {
    width: 45%;
    margin: 5px;
    text-align: center;
    color: #fff !important;
  }
  
  .readersbranches li:nth-child(3) {
    width: 100%;
  }
  
  .readersbranches a {
    color: #fff !important;
    text-decoration: none;
  }
  
  .readersbranches a:hover {
    text-decoration: underline;
  }
  /*sarthak css start*/
  
  .sarthakimgback {
    background: url('../../../public/img/location//sarthakbnr.png') no-repeat;
    background-size: 100% 100%;
  }
  .sarthakheader .sarthaklogo {
    width: 50px;
    height: 50px;
    margin-bottom: 0px;
    border-radius: 50px;
    
    margin-top: 20px;
  }
  .sarthaklogo {
    float: right;
  }
  
  .sarthaktagline {
    color: #d97706;
    font-weight: bold;
    font-size: 1.5rem !important;
  }
  
  .sarthakcontent {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: bold;
    padding: 0 10px;
  }
  
  .sarthakcontact-item  {
    margin: 10px 0;
    font-size: 1.8rem;
  }
  
  .sarthakcontact a {
    color: #77B254 !important;
    text-decoration: none;
    font-weight: bold;
    font-size: 2rem;
  }
  
  .sarthakcontact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    font-size: 1rem;
    background-color: #CBA35C;
    opacity: .6;
    padding: 5px 5px;
  }
  
  .sarthakcontact-item img {
    width: 70px;
    height: 70px;
  }
  
  .sarthakcontact-item a {
    color: #fff !important;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .sarthakcontact-item a:hover {
    text-decoration: underline;
  }
  